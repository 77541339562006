import Link from "next/link"
import { useRouter } from "next/router"
import { memo } from "react"

export const Header: React.FC = memo(() => {
  const router = useRouter()
  return (
    <>
      <nav className="-ml-2 flex items-center justify-center py-1 text-sm font-medium text-zinc-200 md:py-0 md:text-[15px]">
        <Link
          className="mr-1 space-y-px p-2 font-extrabold no-underline md:p-4"
          href="/"
        >
          <div className="h-1.5 w-5 rounded-br bg-zinc-200" />
          <div className="h-1.5 w-3 rounded-br bg-zinc-200/60" />
          <div className="h-1.5 w-1 rounded-br bg-zinc-200/70" />
        </Link>
        {/* <Link
        className={`inline-flex items-center whitespace-nowrap p-2 no-underline hover:text-zinc-200 md:px-5 md:py-4 ${
          router.asPath === "/" ? "" : "text-zinc-400"
        }`}
        href="/"
      >
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          className="-mt-px mr-2.5 hidden md:block"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.9 0.499976C13.9 0.279062 13.7209 0.0999756 13.5 0.0999756C13.2791 0.0999756 13.1 0.279062 13.1 0.499976V1.09998H12.5C12.2791 1.09998 12.1 1.27906 12.1 1.49998C12.1 1.72089 12.2791 1.89998 12.5 1.89998H13.1V2.49998C13.1 2.72089 13.2791 2.89998 13.5 2.89998C13.7209 2.89998 13.9 2.72089 13.9 2.49998V1.89998H14.5C14.7209 1.89998 14.9 1.72089 14.9 1.49998C14.9 1.27906 14.7209 1.09998 14.5 1.09998H13.9V0.499976ZM11.8536 3.14642C12.0488 3.34168 12.0488 3.65826 11.8536 3.85353L10.8536 4.85353C10.6583 5.04879 10.3417 5.04879 10.1465 4.85353C9.9512 4.65827 9.9512 4.34169 10.1465 4.14642L11.1464 3.14643C11.3417 2.95116 11.6583 2.95116 11.8536 3.14642ZM9.85357 5.14642C10.0488 5.34168 10.0488 5.65827 9.85357 5.85353L2.85355 12.8535C2.65829 13.0488 2.34171 13.0488 2.14645 12.8535C1.95118 12.6583 1.95118 12.3417 2.14645 12.1464L9.14646 5.14642C9.34172 4.95116 9.65831 4.95116 9.85357 5.14642ZM13.5 5.09998C13.7209 5.09998 13.9 5.27906 13.9 5.49998V6.09998H14.5C14.7209 6.09998 14.9 6.27906 14.9 6.49998C14.9 6.72089 14.7209 6.89998 14.5 6.89998H13.9V7.49998C13.9 7.72089 13.7209 7.89998 13.5 7.89998C13.2791 7.89998 13.1 7.72089 13.1 7.49998V6.89998H12.5C12.2791 6.89998 12.1 6.72089 12.1 6.49998C12.1 6.27906 12.2791 6.09998 12.5 6.09998H13.1V5.49998C13.1 5.27906 13.2791 5.09998 13.5 5.09998ZM8.90002 0.499976C8.90002 0.279062 8.72093 0.0999756 8.50002 0.0999756C8.2791 0.0999756 8.10002 0.279062 8.10002 0.499976V1.09998H7.50002C7.2791 1.09998 7.10002 1.27906 7.10002 1.49998C7.10002 1.72089 7.2791 1.89998 7.50002 1.89998H8.10002V2.49998C8.10002 2.72089 8.2791 2.89998 8.50002 2.89998C8.72093 2.89998 8.90002 2.72089 8.90002 2.49998V1.89998H9.50002C9.72093 1.89998 9.90002 1.72089 9.90002 1.49998C9.90002 1.27906 9.72093 1.09998 9.50002 1.09998H8.90002V0.499976Z"
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
          ></path>
        </svg>
        <span className="mr-1 sm:hidden">For</span> Figma{" "}
        <span className="ml-1 hidden sm:inline">to Code</span>
      </Link> */}
        {/* <Link
        className={`inline-flex items-center whitespace-nowrap p-2 no-underline hover:text-zinc-200 md:px-5 md:py-4 ${
          router.asPath.startsWith("/slicing") ? "" : "text-zinc-400"
        }`}
        href="/slicing"
      >
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          className="-mt-px mr-2.5 hidden md:block"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.90321 7.29677C1.90321 10.341 4.11041 12.4147 6.58893 12.8439C6.87255 12.893 7.06266 13.1627 7.01355 13.4464C6.96444 13.73 6.69471 13.9201 6.41109 13.871C3.49942 13.3668 0.86084 10.9127 0.86084 7.29677C0.860839 5.76009 1.55996 4.55245 2.37639 3.63377C2.96124 2.97568 3.63034 2.44135 4.16846 2.03202L2.53205 2.03202C2.25591 2.03202 2.03205 1.80816 2.03205 1.53202C2.03205 1.25588 2.25591 1.03202 2.53205 1.03202L5.53205 1.03202C5.80819 1.03202 6.03205 1.25588 6.03205 1.53202L6.03205 4.53202C6.03205 4.80816 5.80819 5.03202 5.53205 5.03202C5.25591 5.03202 5.03205 4.80816 5.03205 4.53202L5.03205 2.68645L5.03054 2.68759L5.03045 2.68766L5.03044 2.68767L5.03043 2.68767C4.45896 3.11868 3.76059 3.64538 3.15554 4.3262C2.44102 5.13021 1.90321 6.10154 1.90321 7.29677ZM13.0109 7.70321C13.0109 4.69115 10.8505 2.6296 8.40384 2.17029C8.12093 2.11718 7.93465 1.84479 7.98776 1.56188C8.04087 1.27898 8.31326 1.0927 8.59616 1.14581C11.4704 1.68541 14.0532 4.12605 14.0532 7.70321C14.0532 9.23988 13.3541 10.4475 12.5377 11.3662C11.9528 12.0243 11.2837 12.5586 10.7456 12.968L12.3821 12.968C12.6582 12.968 12.8821 13.1918 12.8821 13.468C12.8821 13.7441 12.6582 13.968 12.3821 13.968L9.38205 13.968C9.10591 13.968 8.88205 13.7441 8.88205 13.468L8.88205 10.468C8.88205 10.1918 9.10591 9.96796 9.38205 9.96796C9.65819 9.96796 9.88205 10.1918 9.88205 10.468L9.88205 12.3135L9.88362 12.3123C10.4551 11.8813 11.1535 11.3546 11.7585 10.6738C12.4731 9.86976 13.0109 8.89844 13.0109 7.70321Z"
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
          ></path>
        </svg>
        Slicing{" "}
        <span className="relative ml-2 hidden rounded-lg border border-sky-400/40 px-1.5 py-1 text-[10px]/none font-medium text-sky-400 md:inline-block">
          New
        </span>
      </Link> */}
        {/* <Link
        className={`inline-flex items-center whitespace-nowrap p-2 no-underline hover:text-zinc-200 md:mt-0.5 md:px-5 md:py-4 ${
          router.asPath.startsWith("/studio") ? "" : "text-zinc-400"
        }`}
        href="/studio"
      >
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          className="-mt-px mr-2.5 hidden md:block"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 3.25C1 3.11193 1.11193 3 1.25 3H13.75C13.8881 3 14 3.11193 14 3.25V10.75C14 10.8881 13.8881 11 13.75 11H1.25C1.11193 11 1 10.8881 1 10.75V3.25ZM1.25 2C0.559643 2 0 2.55964 0 3.25V10.75C0 11.4404 0.559644 12 1.25 12H5.07341L4.82991 13.2986C4.76645 13.6371 5.02612 13.95 5.37049 13.95H9.62951C9.97389 13.95 10.2336 13.6371 10.1701 13.2986L9.92659 12H13.75C14.4404 12 15 11.4404 15 10.75V3.25C15 2.55964 14.4404 2 13.75 2H1.25ZM9.01091 12H5.98909L5.79222 13.05H9.20778L9.01091 12Z"
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
          ></path>
        </svg>
        Studio{" "}
        <span className="relative ml-3 hidden rounded-lg border border-amber-400/40 px-1.5 py-1 text-[10px]/none font-medium text-amber-400 md:inline-block">
          Soon
        </span>
      </Link> */}
        <Link
          className={`hover:text-zinc-200md:px-5 ml-auto p-2 no-underline  md:p-4 ${
            router.asPath === "/pricing" ? "" : "text-zinc-400"
          }`}
          href="/pricing"
        >
          Pricing
        </Link>
        <Link
          className={`p-2 no-underline hover:text-zinc-200 md:p-4 md:px-5 ${
            router.asPath === "/account" ? "" : "text-zinc-400"
          }`}
          href="/account"
        >
          Account
        </Link>
        <Link
          className={`hidden whitespace-nowrap p-4 no-underline hover:text-sky-200 md:block md:px-5 ${
            router.asPath === "/install" ? "text-sky-200" : "text-sky-300"
          }`}
          href="/install"
        >
          Get started
        </Link>
      </nav>
    </>
  )
})

Header.displayName = "Header"
