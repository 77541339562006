// src/icons.tsx
import {
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  ExclamationCircleIcon,
  FolderIcon,
  FolderOpenIcon,
  HeartIcon,
  InboxIcon,
  InformationCircleIcon,
  NewspaperIcon,
  PlusIcon,
  SparklesIcon,
  Squares2X2Icon,
  TableCellsIcon,
  TrashIcon,
  UserCircleIcon,
  UserIcon,
  UsersIcon
} from "@heroicons/react/24/outline";
import { jsx } from "react/jsx-runtime";
var ICONS = {
  check: /* @__PURE__ */ jsx(CheckIcon, {}),
  checkCircle: /* @__PURE__ */ jsx(CheckCircleIcon, {}),
  chevronDown: /* @__PURE__ */ jsx(ChevronDownIcon, {}),
  chevronUp: /* @__PURE__ */ jsx(ChevronUpIcon, {}),
  clock: /* @__PURE__ */ jsx(ClockIcon, {}),
  empty: /* @__PURE__ */ jsx(InboxIcon, {}),
  folder: /* @__PURE__ */ jsx(FolderIcon, {}),
  folderOpen: /* @__PURE__ */ jsx(FolderOpenIcon, {}),
  grid: /* @__PURE__ */ jsx(Squares2X2Icon, {}),
  heart: /* @__PURE__ */ jsx(HeartIcon, {}),
  info: /* @__PURE__ */ jsx(InformationCircleIcon, {}),
  menu: /* @__PURE__ */ jsx(Bars3Icon, {}),
  newspaper: /* @__PURE__ */ jsx(NewspaperIcon, {}),
  plus: /* @__PURE__ */ jsx(PlusIcon, {}),
  sheet: /* @__PURE__ */ jsx(TableCellsIcon, {}),
  signOut: /* @__PURE__ */ jsx(ArrowLeftOnRectangleIcon, {}),
  sparkles: /* @__PURE__ */ jsx(SparklesIcon, {}),
  trash: /* @__PURE__ */ jsx(TrashIcon, {}),
  user: /* @__PURE__ */ jsx(UserIcon, {}),
  userCircle: /* @__PURE__ */ jsx(UserCircleIcon, {}),
  users: /* @__PURE__ */ jsx(UsersIcon, {}),
  warning: /* @__PURE__ */ jsx(ExclamationCircleIcon, {})
};
export {
  ICONS
};
