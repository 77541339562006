import { memo } from "react"
import { JeroenAvatar } from "ui-legacy"
import { CookieBanner } from "./CookieBanner"

interface FooterProps {
  acceptedCookies: boolean
  setAcceptedCookies: (accepted: boolean) => void
}

export const Footer: React.FC<FooterProps> = memo((props: FooterProps) => {
  return (
    <>
      <CookieBanner {...props} />
      <div className="mt-auto flex items-center space-x-4">
        <a
          target="_blank"
          href="https://twitter.com/jeroenmakes"
          rel="noreferrer"
          className="flex-none rounded-full"
        >
          <JeroenAvatar />
        </a>
        <div className="max-w-md text-xs text-zinc-400">
          Made with love by{" "}
          <a
            target="_blank"
            href="https://twitter.com/jeroenmakes"
            rel="noreferrer"
          >
            @jeroenmakes
          </a>
          . Frontender is a product of{" "}
          <a target="_blank" href="https://webbie.app" rel="noreferrer">
            Hubition
          </a>
          , Lange Viestraat 2b, Utrecht, The Netherlands (registration
          53931459).
        </div>
      </div>
    </>
  )
})

Footer.displayName = "Footer"
