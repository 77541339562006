"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
var src_exports = {};
__export(src_exports, {
  Shortcuts: () => Shortcuts,
  WebbieError: () => WebbieError,
  addToOutline: () => addToOutline,
  cx: () => cx,
  getArrayAfterCreateMultiple: () => getArrayAfterCreateMultiple,
  getArrayAfterDelete: () => getArrayAfterDelete,
  getArrayAfterToggle: () => getArrayAfterToggle,
  getArrayAfterUpdate: () => getArrayAfterUpdate,
  getCurrent: () => getCurrent,
  getCurrentTimestamp: () => getCurrentTimestamp,
  getErrorMessage: () => getErrorMessage,
  getHeadTitle: () => getHeadTitle,
  getInitials: () => getInitials,
  getMailtoLink: () => getMailtoLink,
  getUniqueId: () => getUniqueId,
  getWebbieErrorMessage: () => getWebbieErrorMessage,
  inferOutlineType: () => inferOutlineType,
  isBetween: () => isBetween,
  parseKeyValuePairs: () => parseKeyValuePairs,
  parseStringifiedObject: () => parseStringifiedObject,
  pickRandom: () => pickRandom,
  sendTelegramToJeroen: () => sendTelegramToJeroen,
  statusToColor: () => statusToColor,
  validateEmail: () => validateEmail,
  validateNumber: () => validateNumber
});
module.exports = __toCommonJS(src_exports);

// src/general.ts
var import_uuid = require("uuid");
function cx(...classNames) {
  return classNames.filter(Boolean).join(" ");
}
var getHeadTitle = (app, description, docTitle) => {
  const arr = [];
  if (docTitle) {
    arr.push(docTitle);
  }
  arr.push(app);
  if (!docTitle) {
    arr.push(description);
  }
  return arr.join(" | ");
};
function getInitials(name) {
  const names = name.split(" ");
  const initials = names.map((n) => n[0]);
  const firstInitial = initials[0];
  const lastInitial = initials[initials.length - 1];
  if (lastInitial && initials.length > 1) {
    return firstInitial + lastInitial;
  } else {
    return firstInitial;
  }
}
function getMailtoLink() {
  return Buffer.from(
    "bWFpbHRvOnN1cHBvcnRAZnJvbnRlbmRlci5pbw==",
    "base64"
  ).toString();
}
function getUniqueId() {
  return (0, import_uuid.v4)();
}
function isBetween(value, min, max) {
  return value >= min && value <= max;
}
function statusToColor(status) {
  switch (status) {
    case "success":
      return "green";
    case "info":
      return "blue";
    case "error":
      return "red";
  }
}

// src/errors.ts
var WebbieError = class extends Error {
  constructor(type, message, cause) {
    const computedMessage = getWebbieErrorMessage(type, message, cause == null ? void 0 : cause.message);
    super(computedMessage);
    this.name = "WebbieError";
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, WebbieError);
    }
    this.type = type;
    Object.setPrototypeOf(this, WebbieError.prototype);
  }
};
var getErrorMessage = (error, defaultMessage = "Something went wrong.") => {
  var _a, _b;
  if (error instanceof Error && error.name === "WebbieError") {
    const { type, message, cause } = error;
    return getWebbieErrorMessage(type, message, cause == null ? void 0 : cause.message);
  } else if (typeof error === "string") {
    return getWebbieErrorMessage(error, void 0, void 0);
  } else if (typeof error === "object" && error !== null) {
    if (typeof error.message === "string") {
      return error.message;
    } else if ((_b = (_a = error == null ? void 0 : error.response) == null ? void 0 : _a.data) == null ? void 0 : _b.err) {
      return error.response.data.err;
    }
  }
  return defaultMessage;
};
var getWebbieErrorMessage = (type, message, causeMessage) => {
  if (message && message.includes(" "))
    return message;
  if (causeMessage)
    return causeMessage;
  switch (type) {
    case "not-found":
      return `${message ? `\`${message}\`` : "A variable"} is missing.`;
    case "usage-limit-reached":
      return "You have reached your limit - contact us if you need more.";
    case "no-browser-support":
      return `Your browser does not support ${message || "this feature"}.`;
    case "illegal-user-action":
      return "You are not allowed to do that.";
    case "array-out-of-bounds":
      return `${message ? `\`${message}\`` : "The array"} is out of bounds.`;
    case "not-authenticated":
      return "You need to be logged in.";
    case "no-permissions":
      return "You do not have access to this.";
    case "missing-in-array":
      return `Item inside ${message ? `\`${message}\`` : "an array"} not found.`;
    default:
      return "Something went wrong.";
  }
};

// src/arrays.ts
var getArrayAfterCreateMultiple = (array, data) => {
  const newArray = structuredClone(array);
  newArray.push(...data.map((item) => __spreadValues({ id: getUniqueId() }, item)));
  return newArray;
};
var getArrayAfterDelete = (array, id) => {
  const newArray = structuredClone(array);
  const index = newArray.findIndex((item) => item.id === id);
  if (index > -1) {
    newArray.splice(index, 1);
  } else {
    throw new WebbieError("missing-in-array");
  }
  return newArray;
};
var getArrayAfterToggle = (array, value, forceInclude) => {
  array = array || [];
  const index = array.indexOf(value);
  const shouldInclude = forceInclude !== void 0 ? forceInclude : index === -1;
  const newArray = structuredClone(array);
  if (shouldInclude) {
    if (index === -1) {
      newArray.push(value);
    }
  } else {
    if (index > -1) {
      newArray.splice(index, 1);
    }
  }
  return newArray;
};
var getArrayAfterUpdate = (array, id, data) => {
  const newArray = structuredClone(array);
  const index = newArray.findIndex((item) => item.id === id);
  if (index > -1) {
    ;
    newArray[index] = __spreadValues(__spreadValues({}, newArray[index]), data);
  } else {
    throw new WebbieError("missing-in-array");
  }
  return newArray;
};
function pickRandom(...items) {
  return items[Math.floor(Math.random() * items.length)];
}

// src/components/Shortcuts.tsx
var import_react = require("react");
var Shortcuts = (0, import_react.memo)(
  (props) => {
    const handleKeydown = (0, import_react.useCallback)(
      (e) => {
        var _a, _b;
        if (!props.actions) {
          return;
        }
        const tagName = (_a = e.target) == null ? void 0 : _a.tagName;
        if (["INPUT", "TEXTAREA", "SELECT"].includes(tagName)) {
          return;
        }
        const inModal = (_b = document.activeElement) == null ? void 0 : _b.closest("[role=menu]");
        if (inModal) {
          return;
        }
        const matchingActions = Object.values(props.actions).filter(
          (action) => action.shortcut && e.key === action.shortcut && !action.disabled
        );
        const commandControlPressed = e.ctrlKey || e.metaKey;
        const shiftPressed = e.shiftKey;
        matchingActions.forEach((action) => {
          if (!!action.shortcutCmd === commandControlPressed && !!action.shortcutShift === shiftPressed) {
            e.stopPropagation();
            e.preventDefault();
            action.onAction();
          }
        });
      },
      [props.actions]
    );
    (0, import_react.useEffect)(() => {
      window.addEventListener("keydown", handleKeydown);
      return () => window.removeEventListener("keydown", handleKeydown);
    }, [handleKeydown]);
    return null;
  }
);
Shortcuts.displayName = "Shortcuts";

// src/dates.ts
var import_luxon = require("luxon");
function getCurrent(interval) {
  if (interval === "year") {
    return import_luxon.DateTime.now().toFormat("yyyy");
  } else {
    throw new Error(`Unsupported interval: ${interval}`);
  }
}
function getCurrentTimestamp() {
  return Math.floor(import_luxon.DateTime.now().toSeconds());
}

// src/outlines.ts
function addToOutline(outline, item, parentId) {
  let newOutline;
  if (parentId) {
    const folder = (outline || []).find((doc) => doc.id === parentId);
    if (!folder)
      throw new WebbieError("missing-in-array");
    newOutline = [
      ...(outline || []).filter((item2) => item2.id !== parentId),
      __spreadProps(__spreadValues({}, folder), {
        children: [...(folder == null ? void 0 : folder.children) || [], item]
      })
    ];
  } else {
    newOutline = [...outline || [], item];
  }
  return newOutline;
}
function inferOutlineType(item) {
  if (item.appId)
    return "app";
  if (item.children)
    return "folder";
  return "document";
}

// src/parse.ts
var import_lodash = __toESM(require("lodash.set"));

// src/validation.ts
function validateEmail(email) {
  return !!email.toLowerCase().match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}
function validateNumber(value) {
  return !isNaN(Number(value));
}

// src/parse.ts
function parseKeyValuePairs(input) {
  const lines = input.split("\n");
  const result = {};
  for (const line of lines) {
    const trimmedLine = line.trim();
    if (!trimmedLine) {
      continue;
    }
    const [key, value] = trimmedLine.split(":").map((part) => part.trim().replace(/^['"]|['"]$/g, ""));
    if (key && value) {
      result[key] = value;
    }
  }
  return result;
}
function parseStringifiedObject(input) {
  const result = input.split("\n").map((line) => line.trim());
  const obj = {};
  const currentPath = [];
  for (let i = 0; i < result.length; i++) {
    const line = result[i];
    if (!line) {
      continue;
    }
    let [key, value] = line.split(":").map((part) => part.trim());
    if (value == null ? void 0 : value.includes("{")) {
      currentPath.push(key);
      continue;
    } else if (key == null ? void 0 : key.startsWith("}")) {
      currentPath.pop();
      continue;
    }
    if (!key || !value) {
      continue;
    }
    if (value.endsWith(",")) {
      value = value.slice(0, -1);
    }
    const isString = value.startsWith('"') || value.startsWith("'");
    const isNumber = validateNumber(value);
    if (!isString && !isNumber) {
      continue;
    }
    if (key.startsWith('"') && key.endsWith('"') || key.startsWith("'") && key.endsWith("'")) {
      key = key.slice(1, -1);
    }
    if (value.startsWith('"') && value.endsWith('"') || value.startsWith("'") && value.endsWith("'")) {
      value = value.slice(1, -1);
    }
    const path = [...currentPath, key].join(".");
    (0, import_lodash.default)(obj, path, isNumber ? Number(value) : value);
  }
  return obj;
}

// src/telegram.ts
var JEROEN_TELEGRAM_ID = 544219367;
var TELEGRAM_BOT_TOKEN = "6105546309:AAEImlZxFJtewfXsISjx8tNC1qTGCZWr2mA";
function sendTelegramToJeroen(text, from, args) {
  return __async(this, null, function* () {
    if (!text || !from) {
      return false;
    }
    const data = {
      chat_id: JEROEN_TELEGRAM_ID,
      parse_mode: "HTML",
      text
    };
    if (args == null ? void 0 : args.disablePreview) {
      data.disable_web_page_preview = true;
    }
    console.info("Sending Telegram message to Jeroen...", {
      data,
      token: TELEGRAM_BOT_TOKEN
    });
    return fetch(
      `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`,
      {
        body: JSON.stringify(data),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "POST"
      }
    ).then((res) => res.json()).then(() => {
      return true;
    }).catch(() => false);
  });
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  Shortcuts,
  WebbieError,
  addToOutline,
  cx,
  getArrayAfterCreateMultiple,
  getArrayAfterDelete,
  getArrayAfterToggle,
  getArrayAfterUpdate,
  getCurrent,
  getCurrentTimestamp,
  getErrorMessage,
  getHeadTitle,
  getInitials,
  getMailtoLink,
  getUniqueId,
  getWebbieErrorMessage,
  inferOutlineType,
  isBetween,
  parseKeyValuePairs,
  parseStringifiedObject,
  pickRandom,
  sendTelegramToJeroen,
  statusToColor,
  validateEmail,
  validateNumber
});
