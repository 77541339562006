import Link from "next/link"
import { posthog } from "posthog-js"
import { memo, useCallback, useEffect, useState } from "react"

interface CookieBannerProps {
  acceptedCookies: boolean
  setAcceptedCookies: (accepted: boolean) => void
}

export const CookieBanner: React.FC<CookieBannerProps> = memo(
  (props: CookieBannerProps) => {
    const accepted = props.acceptedCookies
    const setAccepted = props.setAcceptedCookies
    const [mounted, setMounted] = useState(false)

    useEffect(() => {
      setMounted(true)
    }, [])

    // Sync posthog and local storage on mount and change.
    useEffect(() => {
      if (accepted === undefined) {
        return
      }
      if (accepted) {
        if (!posthog.has_opted_in_capturing()) {
          posthog.opt_in_capturing()
        }
        window.localStorage?.setItem("cookieOptIn", "1")
      } else {
        if (!posthog.has_opted_out_capturing()) {
          posthog.opt_out_capturing()
        }
        window.localStorage?.setItem("cookieOptIn", "0")
      }
    }, [accepted])

    const accept = useCallback(() => {
      setAccepted(true)
    }, [setAccepted])

    const reject = useCallback(() => {
      setAccepted(false)
    }, [setAccepted])

    if (accepted !== undefined || !mounted) {
      return null
    }

    return (
      <div className="animate-cookie-banner fixed bottom-3 left-3 right-3 z-50 max-w-sm rounded-xl border border-zinc-600 bg-zinc-700 px-3 py-4 text-white shadow sm:right-auto">
        <div className="px-2 py-1 font-medium">
          This site uses analytics cookies to measure and improve your
          experience.
        </div>
        <div className="mt-4 flex pr-1">
          <button
            onClick={reject}
            type="button"
            className="rounded-lg px-4 py-2 font-medium text-zinc-400 hover:bg-zinc-500/50 hover:text-white"
          >
            Reject
          </button>
          <Link href="/privacy">
            <button
              type="button"
              className="rounded-lg px-4 py-2 font-medium text-zinc-400 hover:bg-zinc-500/50 hover:text-white"
            >
              Details
            </button>
          </Link>
          <button
            onClick={accept}
            type="button"
            className="ml-auto rounded-lg border px-4 py-2 font-medium text-white hover:bg-zinc-500/50"
          >
            Accept
          </button>
        </div>
      </div>
    )
  }
)

CookieBanner.displayName = "CookieBanner"
